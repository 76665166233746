#discordData,#discordInfo{
    display:flex;
    height:50px
}
#callToAction,#callToAction-clicked{
    bottom:0;
    cursor:pointer;
    height:40px;
    left:0;
    min-height:32px;
    min-width:73px;
    position:relative;
    right:0;
    top:0;
    align-self:center;
    margin:0 0 0 10px;
    padding:2px 16px
}
#buttonText,#introText,#serverNameBox,#status{
    text-overflow:ellipsis;
    overflow:hidden
}
#status,#serverImg,#introText {
    display:block;
}
#serverName {
    color:#ffffff;
}
.noselect{
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}
#discordInviteBox{
    background-color:#37393e;
    display:inline-block
}
#discordInvite{
    text-align:left;
    box-sizing:border-box;
    color:#fff;
    background:rgba(47,49,54,.6);
    border:1px solid rgba(47,49,54,.9);
    border-radius:3px;
    font:normal normal 400 normal 16px/16px Whitney,"Helvetica Neue",Helvetica,Arial,sans-serif;
    margin:0;
    padding:10px 15px 10px 10px;
    display:inline-block
}
@media (min-width: 576px) {
    .width400px {
        width: 400px !important;
        padding-right: 15px !important;
    }
    #callToAction {
        margin: 0px !important;
    }
}
#callToAction {
    margin-top: 15px;
}
.width400px {
    padding-right: 10px;
}
.width300px {
    width: 300px;
}
#offlineInd,#onlineInd{
    height:8px;
    width:8px;
    border-radius:50%;
    margin:0 4px 0 0;
    display:block
}
#introText{
    color:#b9bbbe;
    height:16px;
    letter-spacing:.5px;
    text-transform:uppercase;
    flex:1 1 0%;
    font:normal normal 600 normal 12px/16px Whitney,"Helvetica Neue",Helvetica,Arial,sans-serif;
    margin:0 0 8px
}
#serverImg{
    background-position:50% 50%;
    bottom:0;
    height:50px;
    left:0;
    position:relative;
    right:0;
    top:0;
    width:50px;
    border-radius:15px;
    flex:0 0 auto;
    margin:0 10px 0 0
}
#discordInviteError{
    background-color:red;
    color:#fff
}
#discordInfo{
    align-items:stretch;
    justify-content:center;
    flex:1 1 auto;
    flex-flow:column nowrap
}
#callToAction,#statusIndicators{
    align-items:center;
    display:flex
}
#serverNameBox{
    color:#f6f6f7;
    cursor:pointer;
    height:20px;
    font:normal normal 600 normal 16px/20px Whitney,"Helvetica Neue",Helvetica,Arial,sans-serif;
    margin:0 0 4px
}
#serverNameBox:hover{
    text-decoration:underline;
}
#status,#statusIndicators{
    font:normal normal 600 normal 12px/16px Whitney,"Helvetica Neue",Helvetica,Arial,sans-serif;
    color:#72767d;
    height:16px
}
#statusIndicators{
    justify-content:flex-start
}
#onlineInd{
    color:#72767d;
    column-rule-color:#72767d;
    background:#43b581;
    font:italic normal 600 normal 12px/16px Whitney,"Helvetica Neue",Helvetica,Arial,sans-serif
}
#numOnline{
    color:#72767d;
    display:block;
    height:16px;
    font:normal normal 600 normal 12px/16px Whitney,"Helvetica Neue",Helvetica,Arial,sans-serif;
    margin:0 8px 0 0
}
#offlineInd{
    color:#72767d;
    background:#747f8d;
    font:italic normal 600 normal 12px/16px Whitney,"Helvetica Neue",Helvetica,Arial,sans-serif
}
#numTotal{
    color:#72767d;
    display:block;
    height:16px;
    font:normal normal 600 normal 12px/16px Whitney,"Helvetica Neue",Helvetica,Arial,sans-serif
}
#callToAction{
    color:#fff;
    border:0;
    justify-content:center;
    background:#43b581;
    border-radius:3px;
    font:normal normal 500 normal 14px/20px Whitney,"Helvetica Neue",Helvetica,Arial,sans-serif;
    transition:background-color .17s ease 0s
}
#callToAction:hover{
    background-color:#3ca374
}
#callToAction-clicked{
    color:#dcddde;
    display:flex;
    overflow-wrap:break-word;
    word-wrap:break-word;
    align-items:center;
    justify-content:center;
    user-select:none;
    background:rgba(0,0,0,0);
    border:1px solid rgba(114,118,126,.298);
    border-radius:3px;
    font:normal normal 500 normal 14px/20px Whitney,"Helvetica Neue",Helvetica,Arial,sans-serif;
    transition:color .17s ease 0s,background-color .17s ease 0s,border-color .17s ease 0s
}
#discordPoly,#gDiscord{
    white-space:nowrap;
    word-wrap:break-word;
    column-rule-color:#dcddde;
    user-select:none;
    fill:none;
    border:0 #dcddde;
    overflow-wrap:break-word;
    cursor:pointer;
    text-align:center;
    font:normal normal 500 normal 14px/20px Whitney,"Helvetica Neue",Helvetica,Arial,sans-serif
}
#callToAction-clicked:hover{
    border-color:hsla(218,5%,47%,.6)
}
#discordSVG{
    color:#dcddde;
    height:18px;
    width:18px;
    margin:0 0 0 8px
}
#gDiscord{
    fill-rule:evenodd
}
#discordPoly{
    text-decoration:none solid #dcddde;
    fill-rule:evenodd;
    stroke:#dcddde;
    stroke-width:2px;
    caret-color:#dcddde;
    outline:#dcddde 0
}
#joinedDiscord{
    color:#dcddde;
    cursor:pointer;
    display:flex;
    height:20px;
    overflow-wrap:break-word;
    text-align:center;
    font:normal normal 500 normal 14px/20px Whitney,"Helvetica Neue",Helvetica,Arial,sans-serif;
    overflow:hidden
}
#buttonText{
    cursor:pointer;
    display:flex;
    height:20px;
    text-align:center;
    font:normal normal 500 normal 14px/20px Whitney,"Helvetica Neue",Helvetica,Arial,sans-serif;
    margin:0 8.83px
}
#callToAction:hover{
    text-decoration:none;
}
.loadHidden{
    display:none
}
 
